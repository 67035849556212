<template>
    <div id="event">
        <inc_header></inc_header>

        <div class="container">
            <div class="banner"><el-image :src="banner.srcPc" fit="cover"></el-image></div>

            <div class="show">
                <ul>
                    <li v-for="e in events" :key="e.eventId"><span class="post-date">{{ e.postDateFormat }}</span><router-link :to="{ name:'event_show', params:{ id:e.eventId } }" target="_blank"><span class="category">{{ e.category }}</span>{{ e.sign }}</router-link></li>
                </ul>
            </div>
        </div>

        <inc_footer></inc_footer>
    </div>
</template>

<style scoped>
    .banner { height:480px; }
    .banner .el-image { width:100%; height:100%; }

    .show { margin:60px 0; }
    .show ul { margin:0; padding:0; }
    .show ul li { height:35px; line-height:35px; list-style:none; font-size:18px; color:#999; }
    .show ul li span.post-date { font-family:Arial; }
    .show ul li a { margin:0 0 0 25px; color:#999; text-decoration:none; }
    .show ul li a span.category { padding:0 5px 0 0; margin:0 5px 0 0; border-right:1px solid #999; color:#fcc; }
    .show ul li a:hover { color:#000; }
    .show ul li a:hover span {color:#daa; }
</style>

<script>
    import inc_header from "./../components/inc_header.vue"
    import inc_footer from "./../components/inc_footer.vue"

    export default {
        name: "event",
        components: {
            inc_header,
            inc_footer
        },
        data() {
            return {
                banner: {},  // Banner
                events: [],  // 资讯
            }
        },
        created() {
            let that = this;

            // 获取“Banner”
            this.$axios.post(this.$global.config.serverApi+'/banner.php',{ action:"getByCategory", category:"event" }).then( function(response) {
                if (response.data.complete === true) {
                    that.banner = response.data.banner;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });

            // 获取“资讯”
            this.$axios.post(this.$global.config.serverApi+'/event.php',{ action:"list", page:0, rowsPerPage:0 }).then( function(response) {
                if (response.data.complete === true) {
                    that.events = response.data.events;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });
        }
    }
</script>
